import {
	PlusGrid,
	PlusGridRow,
	PlusGridItem,
} from '#app/components/ui/plus-grid.tsx'
import { Button } from '#app/components/ui/button.tsx'
import { Gradient } from '#app/components/ui/gradient.tsx'
import { Link } from '@remix-run/react'

function CallToAction() {
	return (
		<div className="relative pb-16 pt-20 text-center sm:py-24">
			<hgroup>
				<p className="data-[dark]:text-gray-400' font-mono text-xs/5 font-semibold uppercase tracking-widest text-gray-500">
					Get started
				</p>
				<p className="mt-6 text-3xl font-medium tracking-tight text-gray-950 sm:text-5xl">
					Ready to outrank your competitors?
					<br />
					Get your backlink report today.
				</p>
			</hgroup>
			<p className="mx-auto mt-6 max-w-xs text-sm/6 text-gray-500">
				Get the cheat codes to outrank your competitors and climb the search
				rankings.
			</p>
			<div className="mt-6">
				<Button asChild>
					<Link to="/pricing">Get Backlink Report</Link>
				</Button>
			</div>
		</div>
	)
}

function Copyright() {
	return (
		<div className="text-sm/6 text-gray-950">
			&copy; {new Date().getFullYear()} Competitor Backlinks.
		</div>
	)
}

function FooterLinks() {
	return (
		<div className="flex gap-4 text-sm/6 text-gray-950">
			{/* <Link to="/about">About</Link> */}
			<Link to="/login">Login</Link>
		</div>
	)
}

export function Footer() {
	return (
		<footer>
			<Gradient className="relative">
				<div className="rounded-4xl absolute inset-2 bg-white/80" />
				<div className="mx-auto max-w-2xl lg:max-w-7xl">
					<CallToAction />
					<PlusGrid className="pb-16">
						<PlusGridRow className="flex justify-between">
							<div>
								<PlusGridItem className="py-3">
									<FooterLinks />
								</PlusGridItem>
							</div>
							<div className="flex">
								<PlusGridItem className="flex items-center gap-8 py-3">
									<Copyright />
								</PlusGridItem>
							</div>
						</PlusGridRow>
					</PlusGrid>
				</div>
			</Gradient>
		</footer>
	)
}
